<template>
  <div class="terminal-data">
      <div class="title">收益评估</div>
      <div class="chat-content">
          <el-row>
              <el-col :span="24">
                 <div class="chat-item">
                     <p class="chat-title">付费评估</p>
                     <div class="search-form">
                         <div>
                             <span class="form-label">查询日期</span>
                             <el-select v-model="paySelectvalue" placeholder="请选择" style="width: 130px; margin-right: 10px">
                                 <el-option
                                         v-for="item in payOptions"
                                         :key="item.value"
                                         :label="item.label"
                                         :value="item.value">
                                 </el-option>
                             </el-select>
                         </div>
                         <div>
                             <el-button type="primary" @click="getChart1Data">查询</el-button>
                         </div>
                     </div>
                     <div class="totle-data">
                         <div>
                             <span>合计：{{(buyFlowAmount+buyVipAmount).toFixed(2)}}元</span>
                         </div>
                         <span style="margin-right: 20px">购买流量：{{buyFlowAmount.toFixed(2)}}元</span>
                         <span>购买会员：{{buyVipAmount.toFixed(2)}}元</span>
                     </div>
                     <div class="chat-box" ref="chat1"></div>
                 </div>
              </el-col>
          </el-row>
          <el-row>
              <el-col :span="24">
                  <div class="chat-item">
                      <p class="chat-title">订单数量</p>
                      <div class="search-form">
                          <div>
                              <span class="form-label">查询日期</span>
                              <el-select v-model="orderSelectvalue" placeholder="请选择" style="width: 130px">
                                  <el-option
                                          v-for="item in orderOptions"
                                          :key="item.value"
                                          :label="item.label"
                                          :value="item.value">
                                  </el-option>
                              </el-select>
                              <el-date-picker
                                      v-if="orderSelectvalue === '1'"
                                      style="width: 150px; margin-left: 10px"
                                      v-model="orderDay"
                                      :type="orderDatePickerType"
                                      :picker-options="pickerOptions"
                                      value-format="yyyy-MM-dd"
                                      placeholder="选择日期">
                              </el-date-picker>
                          </div>
                          <div>
                              <el-button type="primary" @click="getChart2Data">查询</el-button>
                          </div>
                      </div>
                      <div class="totle-data">
                          <div>
                              <span>总订单数：{{orderNum}}</span>
                              <span>订单成功数：{{successNum}}</span>
                          </div>
                          <span>订单总金额：{{successAmount}}元</span>
                      </div>
                      <div class="chat-box" ref="chat2">

                      </div>
                  </div>
              </el-col>
          </el-row>
      </div>
  </div>
</template>

<script>
import {orderQuantity, payDynamics} from "../../api/chart"

export default {
    name: "terminalData",
    data() {
        return {
            payDate: new Date(),
            orderDay: new Date(),
            payOptions: [ {
                value: '1',
                label: '近7天'
            }, {
                value: '2',
                label: '近15天'
            }, {
                value: '3',
                label: '近30天'
            },
                {
                    value: '4',
                    label: '近1年'
                }],
            orderOptions: [ {
                value: '1',
                label: '按天查询'
            }, {
                value: '2',
                label: '近7天'
            }, {
                value: '3',
                label: '近15天'
            }, {
                value: '4',
                label: '近30天'
            },
                {
                    value: '5',
                    label: '近1年'
                }],
            paySelectvalue:"1",
            orderSelectvalue:"1",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            myChart1:null,
            myChart2:null,
            // chart1汇总
            buyFlowAmount:0,
            buyVipAmount:0,
            //chart2汇总
            orderNum:0,
            successAmount:0,
            successNum:0,
        }
    },
    computed:{
        datePickerType (){
            switch (this.paySelectvalue){
                case "1":
                    return 'week'
                case "2":
                    return 'month'
                case "3":
                    return 'year'
                default:
                    return 'date'
            }
        },
        orderDatePickerType(){
            switch (this.orderSelectvalue){
                case "1":
                    return 'date'
                case "2":
                    return 'week'
                case "3":
                    return 'month'
                case "4":
                    return 'year'
                default:
                    return 'date'
            }
        }
    },
    mounted() {
        this.getChart1Data()
        this.initChart1()
        this.initChart2()
    },
    methods:{
        async getChart1Data(){
            try{
                const queryParams = {
                    dateType: this.paySelectvalue
                }
                const r = await payDynamics(queryParams)
                if(r.code === 0){
                    const rData = r.data
                    this.myChart1.setOption({
                        xAxis: {
                            data: rData.map(item => item.countDate)
                        },
                        series: [
                            {
                                label:{
                                    show: this.paySelectvalue === '1' || this.paySelectvalue === '2'
                                },
                                data: rData.map(item => item.buyFlowAmount.toFixed(2))
                            },
                            {
                                label:{
                                    show: this.paySelectvalue === '1' || this.paySelectvalue === '2'
                                },
                                data: rData.map(item => item.buyVipAmount.toFixed(2))
                            }
                        ]
                    })
                    this.buyFlowAmount = 0
                    rData.forEach(item=>{
                        this.buyFlowAmount = this.buyFlowAmount + item.buyFlowAmount
                    })
                    this.buyVipAmount = 0
                    rData.forEach(item=>{
                        this.buyVipAmount = this.buyVipAmount + item.buyVipAmount
                    })
                }
            }catch (e) {
                console.error(e)
            }
        },
        async getChart2Data(){
          try{
              const queryParams = {
                  dateType: this.orderSelectvalue,
                  date: this.orderDay
              }
              const r = await orderQuantity(queryParams)
              console.log(r)
              if(r.code === 0){
                  const chatData = r.data
                  if(this.orderSelectvalue === '1'){
                      const time = ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59',"21:00-23:59"]
                      const data = []
                      for (let i = 0; i < time.length; i++) {
                          const index = (i+1) *3
                          let orderNum = 0
                          let successAmount = 0
                          let successNum = 0
                          for (let j = 0; j < chatData.length; j++) {
                              if(chatData[j].hour <= index && chatData[j].hour > index-3) {
                                  orderNum += chatData[j].orderNum
                                  successAmount += chatData[j].successAmount
                                  successNum += chatData[j].successNum
                              }
                          }
                          data.push({
                              orderNum,
                              successAmount,
                              successNum
                          })
                          this.orderNum = this.orderNum+orderNum
                          this.successAmount = this.successAmount+successAmount
                          this.successNum = this.successNum + successNum
                      }
                      this.myChart2.setOption({
                          xAxis:{
                              data:time
                          },
                          series:[
                              {
                                  data: data.map(item=>item.orderNum)
                              },
                              {
                                  data: data.map(item=>item.successNum)
                              },
                              {
                                  data: data.map(item=>item.successAmount)
                              }
                          ]
                      })
                      return
                  }
                  this.myChart2.setOption({
                      xAxis: {
                          data: chatData.map(item => item.countDate)
                      },
                      series: [
                          {
                              data: chatData.map(item => item.orderNum)
                          },
                          {
                              data: chatData.map(item => item.successNum)
                          },
                          {
                              data: chatData.map(item=>item.successAmount)
                          }
                      ]
                  })
                  this.orderNum = 0
                  chatData.forEach(item=>{
                      this.orderNum = this.orderNum + item.orderNum
                  })
                  this.successAmount = 0
                  chatData.forEach(item=>{
                      this.successAmount = this.successAmount + item.successAmount
                  })
                  this.successNum = 0
                  chatData.forEach(item=>{
                      this.successNum = this.successNum + item.successNum
                  })
              }

          }catch (e) {
              console.error(e)
          }
        },
        initChart1(){
            // 基于准备好的dom，初始化echarts实例
            this.myChart1 = this.$echarts.init(this.$refs.chat1);
            // 绘制图表
            this.myChart1.setOption({
                toolbox: {
                    feature: {
                        saveAsImage: { show: true }
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['购买流量', '购买会员'],
                    orient: 'horizontal',
                    bottom: '10',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    top:"25%",
                    containLabel: true
                },
                xAxis:  [
                    {
                        type: 'category',
                        data: [],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}元'
                        }
                    },
                    {
                        type: 'value',
                    }
                ],
                series: [
                    {
                        name: '购买流量',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元';
                            }
                        },
                        label: {
                            show: true,
                            position: 'top',
                            formatter:"{c}元",
                            size:'10'
                        },
                        data: [

                        ]
                    },
                    {
                        name: '购买会员',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元';
                            }
                        },
                        label: {
                            show: true,
                            position: 'top',
                            formatter:"{c}元",
                        },
                        data: [

                        ]
                    },
                ]
            });
        },
        initChart2(){
            // 基于准备好的dom，初始化echarts实例
            this.myChart2 = this.$echarts.init(this.$refs.chat2);
            // 绘制图表
            this.myChart2.setOption( {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['总订单数', '订单成功数', '成功充值金额'],
                    orient: 'horizontal',
                    bottom: '10',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    top:"25%",
                    containLabel: true
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: [
                    {
                        type: 'value',
                    },
                    {
                        type: 'value',
                        name: '充值金额',
                        axisLabel: {
                            formatter: '{value}元'
                        }
                    }
                ],
                series: [
                    {
                        name: '总订单数',
                        type: 'line',
                        data: []
                    },
                    {
                        name: '订单成功数',
                        type: 'line',
                        data: []
                    },
                    {
                        name: '成功充值金额',
                        type: 'line',
                        yAxisIndex:1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元';
                            }
                        },
                        data: []
                    },
                ]
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.title{
  font-size: 1.6rem;
  color: #333;
  padding-bottom: 10px;
  font-weight: bold;
  padding-left: 1.4rem;
}
.chat-content{
  box-sizing: border-box;
}
.chat-item{
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: .7rem;
  position: relative;
}
.chat-title{
  font-size: 1.29rem;
  color: #333333;
  margin-bottom: 1.4rem;
}
.chat-box{
  background: #f0eeee;
  min-height: 26rem;
}
.totle-data{
  position: absolute;
  top:160px;
  left: 0;
  z-index: 999;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  div{
    flex: 1;
    span{
      margin-right: 20px;
    }
  }
  span{
    color: #333;
  }
}
.search-form{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;
  .form-label{
    padding-right: 1rem;
  }
}
</style>

import service from "@/utils/ajax"

//各终端类型登录次数
export const platformStatistics = (params)=>{
    return service({
        url:"/v1/statisticsChart/platformStatistics",
        method:"GET",
        params
    })
}
//下载数据
export const downloadVideo = (params)=>{
    return service({
        url:"/v1/statisticsChart/downloadVideo",
        method:"GET",
        params
    })
}
//播放数据
export const playVideo = (params)=>{
    return service({
        url:"/v1/statisticsChart/playVideo",
        method:"GET",
        params
    })
}
//接口数据
export const apiCountData = (params)=>{
    return service({
        url:"/v1/statisticsChart/apiCountData",
        method:"GET",
        params
    })
}

//查询平台用户总量动态
export const totalDynamics = (params)=>{
    return service({
        url:"/v1/statisticsChart/totalDynamics",
        method:"GET",
        params
    })
}

//查询平台用户注册动态
export const reqUpdates = (params)=>{
    return service({
        url:"/v1/statisticsChart/reqUpdates",
        method:"GET",
        params
    })
}

//获取用户活跃度统计数据
export const activeTime = (params)=>{
    return service({
        url:"/v1/statisticsChart/activeTime",
        method:"GET",
        params
    })
}

//查询平台用户性别比例
export const sexCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/sexCount",
        method:"GET",
        params
    })
}

//查询平台用户年龄段数据
export const ageRatio = (params)=>{
    return service({
        url:"/v1/statisticsChart/ageRatio",
        method:"GET",
        params
    })
}

//查询系统总人数
export const countUserNum = (params)=>{
    return service({
        url:"/v1/statisticsChart/countUserNum",
        method:"GET",
        params
    })
}

//查询统计系统总注册人数-分平台
export const countReqUserNum = (params)=>{
    return service({
        url:"/v1/statisticsChart/countReqUserNum",
        method:"GET",
        params
    })
}

//查询付费动态统计数据
export const payDynamics = (params)=>{
    return service({
        url:"/v1/statisticsChart/payDynamics",
        method:"GET",
        params
    })
}

//查询订单数量统计数据
export const orderQuantity = (params)=>{
    return service({
        url:"/v1/statisticsChart/orderQuantity",
        method:"GET",
        params
    })
}

//获取视频热度统计数据
export const videoHotCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/videoHotCount",
        method:"GET",
        params
    })
}

//获取各类型内容分类浏览次数统计数据
export const videoClassify = (params)=>{
    return service({
        url:"/v1/statisticsChart/videoClassify",
        method:"GET",
        params
    })
}

//获取用户搜索词排行
export const searchResult = (params)=>{
    return service({
        url:"/v1/statisticsChart/searchResult",
        method:"GET",
        params
    })
}

//获取各类型内容浏览次数
export const viewsNum = (params)=>{
    return service({
        url:"/v1/statisticsChart/viewsNum",
        method:"GET",
        params
    })
}

//获取各类型内容分享次数
export const shareVideo = (params)=>{
    return service({
        url:"/v1/statisticsChart/shareVideo",
        method:"GET",
        params
    })
}
